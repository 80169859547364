import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In a place of ethnic diversity, there will always be a lens with which people consciously or unknowingly act and interact a certain way. The common issue of People of Colour (PoC) being stopped regularly by the police in the United States is an example of a particular social group being treated and interacted with in a certain way. There are statistically more PoCs pulled over by the police than caucasian people, and this is due to how people perceive that social group and why they have this certain lens. A video from the ACLU featuring comedian Elon James White discusses and instructs what PoC motorists should do when they are stopped by the police or any kind of officer. The video discusses how this social group is represented and how to act around the particular lens people would have for them (PoC’s).`}</p>
    <p>{`The video is set in a more comedic tone, and features an African-American comedian as the narrator, which boosts confirmation that this is a common issue, as the narrator discusses a personal incident. In the beginning of the video, titled: The ACLU & Elon James White: What To Do When You're Stopped By Police, at the 20 second mark, James White brings up, sarcastically, how he has never had problems with an officer and then continues to point at his hand, a gesture to show his skin tone (ACLU, 2011). In this video, people of colour are represented in an oppressed way, portraying this group as one that is commonly harassed because of physical appearance. In the video rights and actions are discussed, and there is also specific mention of different rights that a person has over a police officer. By setting the instructional video in a comedic light, this eases the audience into listening to the narrator and inforces that though this is a video about the still relevant discrimination against PoC’s, the video is not a form of protest and mostly wants to show people how to deal with racial profiling and police.`}</p>
    <p>{`This ACLU video discusses specifically what to do in the event of being stopped by the police, and how to act and what a person can refuse. In the video at 1:20, James White discusses how people have a right to resist an officer searching a vehicle, and before this, brings up how people have a right to remain silent and a right to an attorney (ACLU, 2011). People of Colour are targeted in this video, as previously mentioned they are statistically more likely to be stopped by police. Further, at 3 minutes in the film, the narrator specifically addresses people of Spanish speaking countries, as they are part of the social group which this is targeted to. The effect on the audience this gives is that it not only shows how African Americans are discriminated against by having a black narrator, but also that there is also racial profiling against Mexican and Latin people, informing people of the scale and severity of the issue.`}</p>
    <p>{`This video, in general, addresses people who are being stopped by the police, which happens to be People of Colour, and represents this group in a way, which shows that people do not know their rights or what to say, and that they need to be careful, like mentioning the fact that people need to be clear when they are reaching for something, and they must not resist arrest. The reasoning behind this video and its target audience is because of the statistics and previous cases of the kind of people who were stopped by the police.`}</p>
    <p>{`The video is specifically targeting People of Colour, as in America, African Americans are 31% more likely to be pulled over than white people, and Hispanic people are 23% more likely to be pulled over (Ingraham, 2014). From these statistics and past issues, such as the incident of a police officer shooting African American Walter Scott in the back in 2015, the video has been formed in a way, where there are clear instructions as to what to do, because it could save someone’s life, or keep them out of prison. People are talked to in this direct way in the video, because it needs to be understood how to act in this type of situation, and what to say, for example stating that “I have a right to an attorney!”, or “I do not give you permission to search my car!” (ACLU, 2011). For the audience, this makes it clear what to say exactly, specifically in a police stop and search. This information informs the audience of the most important things to say, especially to the viewers of colour, which is the major target of the video.`}</p>
    <p>{`Overall, the social group of people of colour are represented in this way of being oppressed and having prejudice on them, because it is statistically true and there is evidence for officers pulling over people who are not caucasian.`}</p>
    <p>{`The video outlines how this particular social group are represented and treated, and represents these people simply as everyone else, however they have experienced prejudice and difficulties, which this video is trying to solve, by discussing how to act when a person/officer uses a racial lens on them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      